<template>
  <div class="w-full py-2">
    <div class="flex justify-between">
      <div class="flex w-full p-4 mt-4 text-blue-800">
        <i class="pi pi-replay text-lg mr-2"></i>
        <p class="text-xl font-bold">Crear Devolución</p>
      </div>
      <div class="flex p-4 mt-4 ">
        <Button label="Atrás" icon="pi pi-arrow-left" class="p-button-raised" @click="$router.go(-1)"/>
      </div>
    </div>
  </div>
  <Card>
    <template #content>
      <div class="w-full text-xxs">
        <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
          <div class="grid grid-rows-3 gap-1 pt-3">
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Código Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="entrada.CardCode" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Nombre Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="entrada.CardName" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Usuario: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="entrada.U_PHR_UserWs" disabled/>
              </div>
            </div>
          </div>
          <div class="grid grid-rows-3 gap-1 pt-1">
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha Contabilización: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <input
                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                  v-model="entrada.TaxDate" type="date">
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de documento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <input
                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                  v-model="entrada.DocDate" type="date">
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de vencimiento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <input
                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                  v-model="entrada.DocDueDate" type="date">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="p-2 mt-6">
        <div>
          <DataTable
            :value="entrada.detalle"
            class="p-datatable-sm text-xxs"
            showGridlines
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="500px"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;" style="min-width:30rem"
                    align="left">
              <template #body="{data}">
                <div class="grid grid-cols-1 w-full">
                  <div class="flex justify-between items-center my-1">
                    <p>
                      <strong>Código Mx: </strong>{{ data.ItemCode }}
                    </p>
                    <div class="flex gap-2">
                      <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                             class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge v-if="data.Controlado" value="Controlado"
                             class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.Regulado" value="Regulado"
                             class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                             class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <p>
                    <strong>Nombre: </strong>{{ data.ItemName }}
                  </p>
                  <p v-if="data.BatchNumbers.length === 0" class="color-red">Faltan cantidades de lotes por asignar a
                    devolución, por favor verificar.</p>
                </div>
              </template>
            </Column>
            <Column field="WhsName" header="Almacén" headerStyle="justify-content: center;"
                    style="min-width:12rem" bodyStyle="justify-content: center;text-align: center"/>
            <Column field="InventarioBod" header="Cantidad en almacén" headerStyle="justify-content: center;"
                    style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                <p>{{ $h.formatNumber(data.InventarioBod) }}</p>
              </template>
            </Column>
            <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;" style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                <p>{{ $h.formatNumber(data.Quantity) }}</p>
              </template>
            </Column>
            <Column field="Price" header="Precio unitario" headerStyle="justify-content: center;"
                    style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                <span>{{ $h.formatCurrency(data.Price) }}</span>
              </template>
            </Column>
            <Column field="LineTotal" header="Total Linea" headerStyle="justify-content: center;"
                    style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                <span>{{ $h.formatCurrency(data.LineTotal) }}</span>
              </template>
            </Column>
            <Column field="" bodyStyle="text-align: center;justify-content: center;"
                    headerStyle="text-align: center;justify-content: center;"
                    style="min-width:8rem"
                    header="Acciones"
                    class="shadow-sm">
              <template #body="slotProps">
                <div class="flex gap-4 justify-center">
                  <Button v-tooltip.top="'Asignar Lotes'"
                          class="p-button-rounded p-button-text p-button-info p-0"
                          @click="asignarLotes(slotProps.data)">
                    <boxIcon class="w-5"/>
                  </Button>
                  <Button icon="pi pi-trash" v-tooltip.top="'Borrar linea'"
                          class="p-button-rounded p-button-text p-button-danger p-0" @click="deleteLines(slotProps)"/>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8 mt-4">
        <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="text-xxs mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              v-model="entrada.Comments"
              :autoResize="true"
              rows="2"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="text-xxs mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              v-model="entrada.JrnlMemo"
              disabled
              :autoResize="true"
              rows="2"
              cols="40"
            />
          </div>
          <div>
          </div>
        </div>
        <div class="text-xxs lg:col-span-1">
          <div class="flex justify-between border-b-2 pb-3">
            <span class="text-gray-600">Total sin descuento:</span>
            <span class="">{{ $h.formatCurrency(totalBeDiscount) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total descuento:</span>
            <span>{{ $h.formatCurrency(totalDiscount) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span class="">{{ $h.formatCurrency(totalTaxes) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total:</span>
            <span class="">{{ $h.formatCurrency((totalBeDiscount - totalDiscount) + totalTaxes) }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-center lg:justify-end align-center pt-4">
        <Button
          icon="pi pi-send"
          label="Crear Documento Devolución"
          class="p-button-rounded p-button-success"
          @click="onSubmit"
        />
      </div>
    </template>
  </Card>
  <modalAsignarLotesDevolucion ref="modalLotesDev"/>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import RecepcionPedidosService from '../../services/rcp-pedidos.service'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'modalDocumentoDevolucion',
  components: {
    modalAsignarLotesDevolucion: defineAsyncComponent(() => import('./components/modalAsignarLotesDevolucion.vue'))
  },
  setup () {
    const modalLotesDev = ref()
    const entrada = ref({})
    const DocumentMarketing = ref({
      DocumentLines: []
    })
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const route = useRoute()
    const router = useRouter()
    const totalBeDiscount = computed(() => {
      if (!entrada.value.detalle) return 0
      return entrada.value.detalle.reduce((total, current) => {
        return total + (current.Quantity * current.Price)
      }, 0)
    })
    const totalDiscount = computed(() => {
      if (!entrada.value.detalle) return 0
      return entrada.value.detalle.reduce((total, current) => {
        return total + ((current.LineTotal * (current.DiscPrcnt / 100)))
      }, 0)
    })
    const totalTaxes = computed(() => {
      if (!entrada.value.detalle) return 0
      return entrada.value.detalle.reduce((total, current) => {
        return total + ((current.LineTotal * (current.TaxRate / 100)))
      }, 0)
    })
    const getDataDevolucion = async () => {
      const { DocEntry } = route.params
      await _RecepcionPedidosService.getDocumentoDevolucion(DocEntry).then(({ data }) => {
        data.DocDate = dayjs().format('YYYY-MM-DD')
        data.DocDueDate = dayjs().format('YYYY-MM-DD')
        data.TaxDate = dayjs().format('YYYY-MM-DD')
        entrada.value = data
      })
    }
    const deleteLines = ({ index }) => {
      if (entrada.value.detalle.length > 1) {
        Swal.fire({
          icon: 'question',
          title: '¿Esta seguro/a?',
          text: '¿Desea eliminar esta linea?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Si, eliminar',
          cancelButtonText: 'No, cancelar'
        }).then((resp) => {
          if (resp.isConfirmed) {
            entrada.value.detalle.splice(index, 1)
          }
        })
      } else {
        Swal.fire({
          icon: 'info',
          text: 'El documento debe tener al menos 1 linea para generar la devolución.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
      }
    }
    const onSubmit = async () => {
      let validate = true
      for (const detail of entrada.value.detalle) {
        delete detail.lotes
        if (detail.BatchNumbers.length === 0) validate = false
      }
      if (!validate) {
        await Swal.fire({
          icon: 'info',
          text: 'Faltan cantidades de lotes por asignar a devolución, por favor verificar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (entrada.value.detalle.length > 1) {
        Swal.fire({
          icon: 'question',
          title: '¿Esta seguro/a?',
          text: '¿Desea generar una devolución a partir de este documento?',
          showConfirmButton: true,
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Si, cancelar',
          cancelButtonText: 'No, continuar'
        }).then(async (resp) => {
          if (resp.isConfirmed) {
            await _RecepcionPedidosService.createDevolucion(entrada.value).then(({ data }) => {
              Swal.fire({
                icon: data.DocNum ? 'success' : 'warning',
                title: data.DocDate ? 'Devolución creada con éxito' : 'Advertencia',
                text: data.DocDate ? `Se creo la devolución Nro ${data.DocNum}` : data.message,
                showConfirmButton: true
              }).then(() => {
                router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones' })
              })
            })
          }
        })
      }
    }
    const asignarLotes = (data) => {
      modalLotesDev.value.openModal(data)
    }
    onMounted(async () => {
      await getDataDevolucion()
    })
    return {
      deleteLines,
      asignarLotes,
      entrada,
      modalLotesDev,
      DocumentMarketing,
      onSubmit,
      totalBeDiscount,
      totalDiscount,
      totalTaxes
    }
  }
}

</script>

<style scoped>

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.p-card.p-card-body {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
